<template>
  <v-container v-if="loadingComponent" class="text-center">
    <v-btn color="primary" disabled>Cargando...</v-btn>
  </v-container>
    <div class="card-box ribbon-box">
     <div class="ribbon ribbon-primary text-center">Mensajes</div>
     <p v-if="loading" disable>Cargando...</p>
     <p v-if="errorMsj" disable>No se encontraron mensajes</p>
     <div class="clearfix"></div>
   
     <div style="overflow-y: auto;">
       <div class="inbox-widget" v-for="mensaje in mensajes" :key="mensaje.id">
         <v-row justify="center" class="m-0">
           <v-col cols="12">
             <v-card outlined class="mb-4" style="transform: scale(1); transform-origin: top left;">
               <v-row no-gutters>
                 <!-- Columna de imagen -->
                 <v-col cols="12" md="4" class="d-flex align-center justify-center mb-2 mb-md-0">
  <img :src="mensaje.profile_image" class="rounded-circle small-image" width="100px" height="100px" alt="imagen de perfil"> {{ mensaje.nombre }}
</v-col>
                 <!-- Columna de contenido -->
                 <v-col cols="12" md="6" class="mb-2 mb-md-0">
                   <p class="inbox-item-author ml-1">Asunto: {{ mensaje.titulo }}</p>
                   <p class="inbox-item-text ml-1">{{ mensaje.descripcion }}</p>
                   <p class="inbox-item-date ml-1">{{ mensaje.fecha }}</p>
                 </v-col>
                 <!-- Columna de botón -->
                 <v-col cols="12" md="2" class="d-flex align-center justify-center">
                   <v-btn class="btn btn-icon btn-sm btn-success btn-small-text" @click="abrirModal(mensaje)">Responder</v-btn>
                   </v-col>
               </v-row>
             </v-card>
           </v-col>
         </v-row>
       </div>
     </div>
   </div>
   
   
   
   
   
   
         <v-dialog v-model="mostrarModal" max-width="500">
           <v-card>
             <v-card-title>
               <span class="headline">Responder a {{ mensajeSeleccionado?.titulo }}</span>
             </v-card-title>
             <v-card-text>
               <v-form>
                 <v-text-field v-model="asunto" label="Asunto"></v-text-field>
                 <v-textarea v-model="descripcion" label="Descripción"></v-textarea>
               </v-form>
             </v-card-text>
             <v-card-actions>
               <v-btn color="blue darken-1" text @click="cerrarModal">Cerrar</v-btn>
               <v-btn color="blue darken-1" text @click="enviarMensaje">Enviar</v-btn>
               <v-alert
          v-if="eserror"
          density="compact"
          :text="errorMsj"
          title="Información:"
          type="warning"
        ></v-alert>
             </v-card-actions>
           </v-card>
         </v-dialog>
     </template>
     
     <script setup>
     import { ref, onMounted,computed, getCurrentInstance } from 'vue';
     import { useStore } from 'vuex';
     import Cookies from "js-cookie";
     
     const loading = ref(true);
     const loadingComponent = ref(true);
     const mensajes = ref([]);
     const mostrarModal = ref(false);
     const mensajeSeleccionado = ref(null);
     const asunto = ref('');
     const descripcion = ref('');
     const errorMsj = ref(false);
     const store = useStore();
     const idMsj = computed(() => store.state.id);
     const { proxy } = getCurrentInstance();
     const eserror = ref(false);
     
   //   const props = defineProps({
   //   idMsj: {
   //     type: Number,
   //     required: true,
   //   },
   // });
     
   const obtenerMensajes = async () => {
     try {
       const token = Cookies.get('token'); // Obtén el token de las cookies
       // Realiza la solicitud a la API
       const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/muestraTodosLosMsjs/${idMsj.value}`, {
       method: 'GET',
       headers: {
           'Authorization': `Bearer ${token}`
       }
   });
   
       // Verifica si la respuesta de la red fue exitosa
       if (!response.ok) {
         throw new Error('No hay mensajes');
       }
   
       // Convierte la respuesta en JSON
       const data = await response.json();
        // Verifica si el código de error indica que no se encontraron mensajes
       if (data.code === 'no_messages_found') {
         console.log('No tienes mensajes');
         loading.value = false; // Marca que la carga ha terminado
         loadingComponent.value = false;
         mensajes.value = [];  // Limpia los mensajes
         errorMsj.value  = true;
       } else {
         // Si hay mensajes, actualiza el estado y muestra los mensajes
         loading.value = false;
         loadingComponent.value = false; 
         mensajes.value = data; 
         //console.log(mensajes.value);
       }
   
     } catch (error) {
       // Maneja cualquier error que ocurra durante la solicitud
       console.error('Error al obtener los mensajes:', error);
       loading.value = false;
       loadingComponent.value = false;
       errorMsj.value  = true;
     }
   };
     
     const abrirModal = (mensaje) => {
       mensajeSeleccionado.value = mensaje;
       mostrarModal.value = true;
     };
     
     const cerrarModal = () => {
       mostrarModal.value = false;
     };
     
    //  const enviarMensaje = () => {
       
    //   console.log('Enviando mensaje:', {
        
    //     id_usuario_envio: idMsj.value,
    //     id_usuario_destino: parseInt(mensajeSeleccionado.value.id, 10),
    //     titulo: asunto.value,
    //      descripcion: descripcion.value,
    //      codigo_mensaje: mensajeSeleccionado.value.codigo_mensaje,
    //    });
       
    //    cerrarModal();
    //  };
    const enviarMensaje = () => {
  const formData = {
    id_usuario_envio: idMsj.value,
    id_usuario_destino: parseInt(mensajeSeleccionado.value.id_usuario_envio, 10),
    titulo: asunto.value,
    descripcion: descripcion.value,
    codigo_mensaje: mensajeSeleccionado.value.codigo_mensaje,
  };
  enviarmsj(formData); 
  errorMsj.value = 'Enviando mensaje';
  eserror.value = true;
  console.log(formData);
};

//Funcion para enviar los datos del msj a la api
async function enviarmsj(data) {
  try {
      const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/enviarmensaje`, {
      method: "POST",
       headers: {
     "Content-Type": "application/json",
      //   Authorization: `Bearer ${Cookies.get("token")}`,      
       },
       body: JSON.stringify(data),
    });
    
    const responseData = await response.json();

    if (response.ok) {
      console.log(responseData);
      errorMsj.value = 'Mensaje enviado con exito!';
     
    } else {
      //eserror.value = true;
      errorMsj.value = responseData.message || "Error desconocido";
      //errors.courseApi = responseData.message || "Error desconocido";
      console.log("No se pudo actualizar los datos del usuario:", responseData.message);
    }
  } catch (error) {
    errorMsj.value = "Error de red. Inténtalo de nuevo.";
   // eserror.value = true;
    //errors.courseApi = "Error de red. Inténtalo de nuevo.";
    console.error("Error al actualizar los datos del usuario:", error);
  }
};
//fin de la funcion de la api
     
     
     
     onMounted(() => {
       obtenerMensajes();
     });
     </script>
     
     <style scoped>
     /* Estilos personalizados */
    
   .btn-small-text {
     font-size: 0.6rem; /* Ajusta este valor según tus necesidades */
   }
     </style>
     