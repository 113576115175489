<template>
  <v-container v-if="loadingComponent" class="text-center">
    <v-btn color="primary" disabled>Cargando...</v-btn>
  </v-container>
  <div v-else>
    <!-- Contenido del dashboard -->
    <div class="content">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <!-- meta -->
                <div class="profile-user-box card-box bg-custom">
                    
                        
                            <div class="media-body text-white  text-center">
                                <h4 class="mt-1 mb-1 font-18">Bienvenido a tu cuenta</h4>
                                <p class="font-13 text-light"></p>
                                <p class="text-light mb-0"></p>
                            </div>
                        
                        <!-- <div class="col-sm-6">
                            <div class="text-right">
                                <button type="button" class="btn btn-light waves-effect"><i class="mdi mdi-account-settings-variant mr-1"></i> Edit Profile</button>
                            </div>
                        </div> -->
                    
                </div>
                <!--/ meta -->
            </div>
        </div>
        <!-- end row -->
        <div class="row">
            <div class="col-xl-6">
                <!-- Personal-Information -->
                <div class="card-box">
                    <h4 class="header-title mt-0 text-dark">Información importante*</h4>
                    <div class="panel-body">
                        <p class="text-muted font-13">Esta sección mostrará novedades: </p>
                        <p class="text-muted font-13"><strong>Promociones :</strong> <span class="m-l-15">Las mas destacadas del momento</span></p>
                        <p class="text-muted font-13"><strong>Cursos :</strong> <span class="m-l-15">Novedades acerca de los cursos</span></p>
                        <p class="text-muted font-13"><strong>Estadísticas :</strong> <span class="m-l-15">Datos que te pueden resultar de interes</span></p>
                        <hr>
                        <!-- <div class="text-left">
                            <p class="text-muted font-13"><strong>Full Name :</strong> <span class="m-l-15">Johnathan Deo</span></p>
                            <p class="text-muted font-13"><strong>Mobile :</strong><span class="m-l-15">(+12) 123 1234 567</span></p>
                            <p class="text-muted font-13"><strong>Email :</strong> <span class="m-l-15">coderthemes@gmail.com</span></p>
                            <p class="text-muted font-13"><strong>Location :</strong> <span class="m-l-15">USA</span></p>
                            <p class="text-muted font-13"><strong>Languages :</strong> <span class="m-l-5"><span class="flag-icon flag-icon-us m-r-5 m-t-0" title="us"></span> <span>English</span> </span><span class="m-l-5"><span class="flag-icon flag-icon-de m-r-5" title="de"></span> <span>German</span> </span><span class="m-l-5"><span class="flag-icon flag-icon-es m-r-5" title="es"></span> <span>Spanish</span> </span><span class="m-l-5"><span class="flag-icon flag-icon-fr m-r-5" title="fr"></span> <span>French</span></span>
                            </p>
                        </div> -->
                        <ul class="social-links list-inline mt-4 mb-0">
                            <li class="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" class="tooltips" href="" data-original-title="Facebook"><i class="fa fa-facebook"></i></a></li>
                            <li class="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" class="tooltips" href="" data-original-title="Twitter"><i class="fa fa-twitter"></i></a></li>
                            <li class="list-inline-item"><a title="" data-placement="top" data-toggle="tooltip" class="tooltips" href="" data-original-title="Skype"><i class="fa fa-skype"></i></a></li>
                        </ul>
                    </div>
                </div>
                <!-- Personal Message -->
                <muestra5msj />
                </div>
                
            <div class="col-xl-6">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="card-box tilebox-one"><i class="icon-layers float-right text-muted"></i>
                            <h6 class="text-muted text-uppercase mt-0">Visitas al perfil</h6>
                            <h2 class="text-dark" data-plugin="counterup">{{visitasPerfil}}</h2><span class="badge badge-custom">+11% </span><span class="text-muted"> periodo anterior</span></div>
                    </div>
                    <!-- end col -->
                    <div class="col-sm-4">
                        <div class="card-box tilebox-one"><i class="icon-paypal float-right text-muted"></i>
                            <h6 class="text-muted text-uppercase mt-0">Mentorias</h6>
                            <h2 class="text-dark"><span data-plugin="counterup">{{mentoriasPerfil}}</span></h2><span class="badge badge-custom">+50% </span><span class="text-muted">periodo anterior</span></div>
                    </div>
                    <!-- end col -->
                    <div class="col-sm-4">
                        <div class="card-box tilebox-one"><i class="icon-rocket float-right text-muted"></i>
                            <h6 class="text-muted text-uppercase mt-0">Mentorias vendidas</h6>
                            <h2 class="text-dark" data-plugin="counterup">{{vendidasPerfil}}</h2><span class="badge badge-custom">+0% </span><span class="text-muted">No disponible</span></div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
                <!-- <div class="card-box">
                    <h4 class="header-title mt-0 mb-3">Experience</h4>
                    <div class="">
                        <div class="">
                            <h5 class="text-custom">Lead designer / Developer</h5>
                            <p class="mb-0">websitename.com</p>
                            <p><b>2010-2015</b></p>
                            <p class="text-muted font-13 mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                        <hr>
                        <div class="">
                            <h5 class="text-custom">Senior Graphic Designer</h5>
                            <p class="mb-0">coderthemes.com</p>
                            <p><b>2007-2009</b></p>
                            <p class="text-muted font-13 mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                    </div>
                </div> -->
                <muestra5publicaciones /> 
                <MuestraSolicitudes />            
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- container -->
</div>


     
  </div>
</template>

<script>
import { ref, onMounted, inject, computed, watch, getCurrentInstance } from "vue";
import Cookies from "js-cookie";

import muestra5msj  from "@/components/chat/muestra5msj.vue";
import muestra5publicaciones  from "@/components/panelPerfil/muestra5publicaciones.vue";
import MuestraSolicitudes from "@/components/panelPerfil/muestraSolicitudes.vue";

export default {
    components: {
    muestra5msj,
    muestra5publicaciones,
    MuestraSolicitudes,
  },
  setup() {
    const { proxy } = getCurrentInstance(); 
    const loadingComponent = ref(true);
    const themeState = inject("theme");
    const toggle = ref(true);
    const visitasPerfil= ref('');
    const mentoriasPerfil= ref('');
    const vendidasPerfil= '*';

    const themeClass = computed(() => {
      return themeState.theme === "dark" ? "dark" : "light";
    });

    //funcion para obtener las visitas del perfil y las mentorias
    async function loadPerfilStadistic() {
        
  try {
    const response = await fetch(`${proxy.$dominio}/wp-json/wp/v2/users/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    const data = await response.json();

    if (response.ok) {
        visitasPerfil.value = data.meta.profile_views || 0;
        mentoriasPerfil.value = data.post_count;
        
    //   console.log(data);
    } else {
      console.error("Error ", data.message);
      }
  } catch (error) {
    console.error("Error ", error);
  }
}

    watch(
      themeClass,
      (newClass) => {
        toggle.value = newClass === "light";
      },
      { immediate: true }
    );

    const onToggleChange = () => {
      themeState.theme = themeState.theme === "dark" ? "light" : "dark";
    };

    onMounted(() => {
      // Simular un proceso de carga con setTimeout
      // setTimeout(() => {
        loadPerfilStadistic(); //cargo las estadisticas del perfil
      loadingComponent.value = false;
      // }, 2000); // 2 segundos de carga simulada
    });

    return {
      onToggleChange,
      themeClass,
      loadingComponent,
      toggle,
      visitasPerfil,
      mentoriasPerfil,
      vendidasPerfil,
      muestra5msj,
      loadPerfilStadistic,
    };
  },
};
</script>

<style scoped>
body{
    background-color: #f3f6f8;
    margin-top:20px;
}
.thumb-lg {
    height: 88px;
    width: 88px;
}
.profile-user-box {
    position: relative;
    border-radius: 5px
}
.bg-custom {
    background-color: #02c0ce!important;
}
.profile-user-box {
    position: relative;
    border-radius: 5px;
}

.card-box {
    padding: 20px;
    border-radius: 3px;
    margin-bottom: 30px;
    background-color: #fff;
}
.inbox-widget .inbox-item img {
    width: 40px;
}

.inbox-widget .inbox-item {
    border-bottom: 1px solid #f3f6f8;
    overflow: hidden;
    padding: 10px 0;
    position: relative
}

.inbox-widget .inbox-item .inbox-item-img {
    display: block;
    float: left;
    margin-right: 15px;
    width: 40px
}

.inbox-widget .inbox-item img {
    width: 40px
}

.inbox-widget .inbox-item .inbox-item-author {
    color: #313a46;
    display: block;
    margin: 0
}

.inbox-widget .inbox-item .inbox-item-text {
    color: #98a6ad;
    display: block;
    font-size: 14px;
    margin: 0
}

.inbox-widget .inbox-item .inbox-item-date {
    color: #98a6ad;
    font-size: 11px;
    position: absolute;
    right: 7px;
    top: 12px
}
/* Para pantallas pequeñas */
@media (max-width: 767.98px) {
  .inbox-widget .inbox-item {
    position: relative;
  }
  .inbox-widget .inbox-item .inbox-item-date {
    position: static;
    text-align: center;
    margin-top: 10px;
  }
}

.comment-list .comment-box-item {
    position: relative
}

.comment-list .comment-box-item .commnet-item-date {
    color: #98a6ad;
    font-size: 11px;
    position: absolute;
    right: 7px;
    top: 2px
}

.comment-list .comment-box-item .commnet-item-msg {
    color: #313a46;
    display: block;
    margin: 10px 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px
}

.comment-list .comment-box-item .commnet-item-user {
    color: #98a6ad;
    display: block;
    font-size: 14px;
    margin: 0
}

.comment-list a+a {
    margin-top: 15px;
    display: block
}

.ribbon-box .ribbon-primary {
    background: #2d7bf4;
}

.ribbon-box .ribbon {
    position: relative;
    float: left;
    clear: both;
    padding: 5px 12px 5px 12px;
    margin-left: -30px;
    margin-bottom: 15px;
    font-family: Rubik,sans-serif;
    -webkit-box-shadow: 2px 5px 10px rgba(49,58,70,.15);
    -o-box-shadow: 2px 5px 10px rgba(49,58,70,.15);
    box-shadow: 2px 5px 10px rgba(49,58,70,.15);
    color: #fff;
    font-size: 13px;
}
.text-custom {
    color: #02c0ce!important;
}

.badge-custom {
    background: #02c0ce;
    color: #fff;
}
.badge {
    font-family: Rubik,sans-serif;
    -webkit-box-shadow: 0 0 24px 0 rgba(0,0,0,.06), 0 1px 0 0 rgba(0,0,0,.02);
    box-shadow: 0 0 24px 0 rgba(0,0,0,.06), 0 1px 0 0 rgba(0,0,0,.02);
    padding: .35em .5em;
    font-weight: 500;
}
.text-muted {
    color: #98a6ad!important;
}

.font-13 {
    font-size: 13px!important;
}
</style>
