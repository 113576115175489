<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

// Usar getters de Vuex para obtener los productos en el carrito
const cartItems = computed(() => store.getters.cartItems);

// Eliminar productos del carrito
const removeFromCart = (productId) => {
  store.dispatch('removeFromCart', productId);
};
const payWithMercadoPago = async () => {
  // Mapea los productos del carrito al formato esperado por la API de MercadoPago
  const items = cartItems.value.map(item => ({
    title: item.name,           // Nombre del producto
    description: item.slug,     // Descripción o slug del producto
    quantity: item.quantity,    // Cantidad
    currency_id: 'ARS',         // Moneda (puede ser 'USD', 'ARS', etc. según tu región)
    unit_price: parseFloat(item.price)  // Precio unitario (convertido a número si es necesario)
  }));

  try {
    const response = await fetch('https://sinapsismentoring.com/wp-json/mercadopago/v1/crear-preferencia', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ items })
    });

    if (!response.ok) throw new Error('Error en la creación de preferencia');

    const { id } = await response.json();

    // Inicializa MercadoPago y abre el checkout
    const mp = new window.MercadoPago('TEST-e4b462e5-e2af-447f-a069-603cb4b64f9d', { locale: 'es-AR' });
    mp.checkout({
      preference: { id },
      autoOpen: true
    });
  } catch (error) {
    console.error('Error creando preferencia:', error);
  }
};


</script>

<template>
  <div>
    <h1>Mi Carrito</h1>
    <ul v-if="cartItems.length">
      <li v-for="item in cartItems" :key="item.id">
        {{ item.name }} - {{ item.quantity }} x {{ item.price }}
        <button @click="payWithMercadoPago(item.id)">Pagar</button>
        <button @click="removeFromCart(item.id)">Eliminar</button>
      </li>
    </ul>
    <p v-else>Tu carrito está vacío</p>
  </div>
</template>

