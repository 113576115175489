<template>
  <div class="payment-container">
    <h1>Realizar Pago</h1>
    
    <form @submit.prevent="processPayment">
      <div>
        <label for="paymentMethod">Método de Pago</label>
        <select v-model="selectedPaymentMethod" id="paymentMethod" required>
          <option value="visa">Tarjeta de Crédito (Visa)</option>
          <option value="account_money">Dinero en Cuenta</option>
        </select>
      </div>

      <!-- Campos de tarjeta de crédito -->
      <div v-if="selectedPaymentMethod === 'visa'">
        <div>
          <label for="cardNumber">Número de tarjeta</label>
          <input v-model="cardNumber" id="cardNumber" type="text" placeholder="Número de tarjeta" required />
        </div>
        <div>
          <label for="expiryMonth">Mes de vencimiento (MM)</label>
          <input v-model="expiryMonth" id="expiryMonth" type="text" placeholder="MM" required />
        </div>
        <div>
          <label for="expiryYear">Año de vencimiento (YY)</label>
          <input v-model="expiryYear" id="expiryYear" type="text" placeholder="YY" required />
        </div>
        <div>
          <label for="securityCode">Código de seguridad (CVV)</label>
          <input v-model="securityCode" id="securityCode" type="text" placeholder="CVV" required />
        </div>
        <div>
          <label for="cardholderName">Nombre del titular</label>
          <input v-model="cardholderName" id="cardholderName" type="text" placeholder="Nombre del titular" required />
        </div>
        
        <!-- Botón de pago para tarjeta -->
        <button type="button" @click="processPayment">Pagar con Tarjeta</button>
      </div>

      <!-- Botón para generar preferencia (solo para "Dinero en Cuenta") -->
      <div v-if="selectedPaymentMethod === 'account_money'">
        <button type="button" @click="crearPreferencia">Generar Preferencia</button>
        <div id="wallet_container"></div>
      </div>

      <!-- Botón de pago (solo después de generar preferencia) -->
      <div v-if="preferenceId">
        <button type="button" @click="verificarEstadoPago">Confirmar el pago y terminar</button>
      </div>
    </form>

    <div v-if="paymentResult">
      <h2>Resultado del Pago:</h2>
      <p>{{ paymentResult }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const cardNumber = ref('');
const expiryMonth = ref('');
const expiryYear = ref('');
const securityCode = ref('');
const cardholderName = ref('');
const selectedPaymentMethod = ref('visa'); // Por defecto, se selecciona "Visa"
const paymentResult = ref('');
const preferenceId = ref('');
const userId = computed(() => store.state.id);

// MercadoPago Public Key (Reemplaza con la tuya)
const publicKey = 'APP_USR-f9bb6d8f-720c-47c7-8bab-019f1fba5926';
let mp = null;

onMounted(() => {
  mp = new window.MercadoPago(publicKey, {
    locale: 'es-AR'
  });
});
// Watcher para limpiar resultados al cambiar método de pago
watch(selectedPaymentMethod, () => {
      paymentResult.value = '';
      if (selectedPaymentMethod.value === 'visa') {
        cardNumber.value = '';
        expiryMonth.value = '';
        expiryYear.value = '';
        securityCode.value = '';
        cardholderName.value = '';
        preferenceId.value = '';
      } else {
        preferenceId.value = ''; // Limpiar preferenceId si es necesario
      }
    });

    
    const crearPreferencia = async () => {
  const response = await fetch('https://sinapsismentoring.com/wp-json/miapi/v1/crear-preferencia', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      title: 'Compra de producto',
      price: 1,
      user_id: userId.value, // Cambia esto según tu lógica
    }),
  });

  const data = await response.json();
  preferenceId.value = data.id; // Obtener el ID de la preferencia
  iniciarPago(); // Llama a iniciarPago aquí si deseas iniciar el pago inmediatamente
};

const iniciarPago = () => {
  if (preferenceId.value) {
    const mp = new MercadoPago(publicKey, {
      locale: 'es-AR'
    });

    // Crea el brick para el wallet
    mp.bricks().create("wallet", "wallet_container", {
      initialization: {
        preferenceId: preferenceId.value, // Usar la preferencia creada
      },
    }).then(() => {
      console.log("Wallet creado exitosamente.");
    }).catch((error) => {
      console.error('Error al crear el wallet:', error);
    });
  }
};


const processPayment = async () => {
  try {
    if (selectedPaymentMethod.value === 'visa') {
      const cardTokenResponse = await mp.createCardToken({
        cardNumber: cardNumber.value,
        cardholderName: cardholderName.value,
        cardExpirationMonth: expiryMonth.value,
        cardExpirationYear: expiryYear.value,
        securityCode: securityCode.value
      });

      const cardToken = cardTokenResponse.id;

      if (!cardToken) {
        throw new Error('No se pudo generar el token de la tarjeta');
      }

      const paymentData = {
        transaction_amount: 100.0,
        token: cardToken,
        description: 'Compra de producto',
        installments: 1,
        payment_method_id: 'visa',
        payer: {
          email: 'test@example.com'
        },
        user_id: userId.value // Agregar el userId aquí
      };

      const response = await fetch('https://sinapsismentoring.com/wp-json/mercadopago/v1/create-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData)
      });

      const result = await response.json();

      if (result.status === 'approved') {
        paymentResult.value = 'Pago aprobado con éxito.';
      } else {
        paymentResult.value = `Error en el pago: ${result.status}, Motivo: ${result.status_detail}`;
      }
    }
  } catch (error) {
    paymentResult.value = `Error: ${error.message}`;
  }
};


</script>




<style scoped>
.payment-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.payment-container h1 {
  text-align: center;
}

.payment-container form div {
  margin-bottom: 15px;
}

.payment-container button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.payment-container button:hover {
  background-color: #0056b3;
}

.payment-container h2 {
  margin-top: 20px;
}

.payment-container p {
  font-size: 1.2em;
  color: green;
}
</style>
