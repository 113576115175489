<!-- Revisar que las publicaciones se muestren con token verification -->
 <!-- Revisar que las publicaciones se muestren con token verification -->
  <!-- Revisar que las publicaciones se muestren con token verification -->
<template>
    <div class="card-box text-dark">
  <h4 class="header-title text-dark mb-3">Mis Solicitudes de Sinapsis</h4>
  <p v-if="loading" disable>Cargando...</p>
  <p v-if="errorMsj" disable>No se encontraron Solicitudes</p>

  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>#</th>
          <th>Nombre del Mentee</th>
          <th>Fecha de Solicitud</th>
          <th>Estado</th>
          <th>Acción</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(solicitud, index) in publicaciones" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ solicitud.mentee_name || 'Nombre no asignado' }}</td>
          <td>{{ solicitud.created_at }}</td>
          <td><span class="label label-info">Pending</span></td>
          <td>
            <button @click="aceptarSolicitud(solicitud.mentee_id)" type="button" class="btn btn-icon btn-sm waves-effect waves-light btn-success">Aceptar</button>
            <button @click="rechazarSolicitud(solicitud.mentee_id)" type="button" class="btn btn-icon btn-sm waves-effect waves-light btn-danger">Rechazar</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

</template>

<script setup>
    import { ref, getCurrentInstance, onMounted, computed, } from 'vue';
  import { useStore } from 'vuex';
  import Cookies from "js-cookie";
  import { useRouter } from 'vue-router';

  const router = useRouter();
  const loading = ref(true);
  const errorMsj = ref(false);
  const publicaciones = ref([]);
  const userId = computed(() => store.state.id);
  const store = useStore();
  const { proxy } = getCurrentInstance();

  const linkToMentoria =  (slug) => {
    router.push(`/mentoria/${slug}`);
     
        };
        
        const obtenerSolicitudes = async () => {
  
          try {
    const token = Cookies.get('token'); 
    publicaciones.value = [];  // Limpia las solicitudes
    loading.value = true;      // Marca que la carga ha comenzado
    errorMsj.value = false;    // Resetea el mensaje de error
    
    // Realiza la solicitud a la API
    const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/solicitudes-pendientes/${userId.value}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    // Verifica si la respuesta de la red fue exitosa
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Convierte la respuesta en JSON
    const data = await response.json();

    // Verifica si no se encontraron solicitudes
    if (data.code === 'no_solicitudes') {
      console.log('No tienes Solicitudes');
      loading.value = false; // Marca que la carga ha terminado
      publicaciones.value = [];  // Limpia las solicitudes
      errorMsj.value = true;
    } else {
      // Si hay solicitudes, actualiza el estado y muestra las solicitudes
      loading.value = false;
      publicaciones.value = data;
    }
  } catch (error) {
    // Maneja cualquier error que ocurra durante la solicitud
    console.error('Error al obtener las solicitudes:', error);
    loading.value = false;
    errorMsj.value = true;
  }
};
const aceptarSolicitud = async (menteeId) => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/administrar-solicitudes`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        mentorId: userId.value, // O el ID del mentor actual
        menteeId: menteeId,
        accion: 'accepted'
      })
    });
    
    if (!response.ok) {
      throw new Error('Error aceptando la solicitud');
    }

    console.log('Solicitud aceptada');
    obtenerSolicitudes(); // Actualiza la lista de solicitudes
  } catch (error) {
    console.error(error);
  }
};

const rechazarSolicitud = async (menteeId) => {
  try {
    const token = Cookies.get('token');
    const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/administrar-solicitudes`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        mentorId: userId.value, // O el ID del mentor actual
        menteeId: menteeId,
        accion: 'rejected'
      })
    });
    
    if (!response.ok) {
      throw new Error('Error rechazando la solicitud');
    }

    console.log('Solicitud rechazada');
    obtenerSolicitudes(); // Actualiza la lista de solicitudes
  } catch (error) {
    console.error(error);
  }
};



onMounted(() => {
  obtenerSolicitudes();
  });

</script>